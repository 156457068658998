defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Mediahub/EpisodesGrid', [
  'Shared/Framework/Ensighten/Scripts/Ensighten',
], function (Ensighten) {

  const addToggleToDropdowns = (dropdowns) => {
    dropdowns.forEach((dropdown) => {
      const select = dropdown.querySelector('.mediahub-dropdown__select');
      const selectedLabel = dropdown.querySelector('.mediahub-dropdown__selected');
      select.addEventListener('click', () => {
        dropdown.classList.toggle('mediahub-dropdown--open');
      });

      const options = dropdown.querySelectorAll('.mediahub-dropdown__option');
      options?.forEach((option) => {
        option.addEventListener('click', () => {
          const seriesContent = document.querySelectorAll('[data-dropdown-content]');
          selectedLabel.innerText = option.innerText;
          options.forEach((option) => option.classList.remove('mediahub-dropdown__option--selected'));
          option.classList.add('mediahub-dropdown__option--selected');
          Ensighten.pushGaEvent('pokerkaelderen', 'filter', option.innerText);

          if (option.hasAttribute('data-dropdown-option')) {
            // Show selected series.
            const seriesId = option.getAttribute('data-dropdown-option');

            seriesContent.forEach((series) => {
              if (series.getAttribute('data-dropdown-content') !== seriesId) {
                series.style.display = 'none';
              } else {
                series.style.display = '';
              }
            });

          } else {
            // Show all series.
            seriesContent.forEach((series) => {
              series.style.display = '';
            });
          }
        });
      });
    });
  };

  const addClickOutsideToCloseDropdownsToggle = () => {
    const dropdowns = document.querySelectorAll('.mediahub-dropdown');
    if (!dropdowns.length) return;
    document.addEventListener('click', (ev) => {
      dropdowns.forEach((dropdown) => {
        if (!dropdown.contains(ev.target)) {
          dropdown.classList.remove('mediahub-dropdown--open');
        }
      });
    });
  };

  const initialize = () => {
    const dropdowns = [...document.querySelectorAll('.mediahub-dropdown')];
    if (!dropdowns.length) return;
    addToggleToDropdowns(dropdowns);
    addClickOutsideToCloseDropdownsToggle();
  };

  initialize();

});
