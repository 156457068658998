defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Components/Pagination',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
  ],
  function (m, Render) {
    var render = Render();

    return {
      controller: function (options) {
        options.maxNumbersToShow = m.prop(10);
        options.numbersOnLeftSide = m.prop(Math.ceil(options.maxNumbersToShow() / 2) - 1);
        options.numbersOnRightSide = m.prop(Math.floor(options.maxNumbersToShow() / 2));
        options.paginationContainerWidth = m.prop(0);

        options.changePage = function (e) {
          options.activePage(e.target.innerText - 1);
          m.redraw();
        }.bind(options);

        options.previousPage = function () {
          var page = options.activePage() - 1;
          if (page >= 0) {
            options.activePage(page);
            m.redraw();
          }
        }.bind(options);

        options.nextPage = function () {
          var page = options.activePage() + 1;
          if (page < options.list().length) {
            options.activePage(page);
            m.redraw();
          }
        }.bind(options);

        options.calculateMaxNumbersToShow = function (width) {
          var allocatedPixelsForEachNumber = 45;

          var numbersToShow = Math.floor(width / allocatedPixelsForEachNumber);
          numbersToShow = Math.min(numbersToShow, 10);

          options.maxNumbersToShow(numbersToShow);
          options.numbersOnLeftSide = m.prop(Math.ceil(options.maxNumbersToShow() / 2) - 1);
          options.numbersOnRightSide = m.prop(Math.floor(options.maxNumbersToShow() / 2));
          m.redraw();
        };


        render.ready.then(function (nodes) {
          var pagination = nodes['pagination'];

          if (!pagination.$element) {
            return;
          }

          options.calculateMaxNumbersToShow(pagination.$element.offsetWidth);

          // trigger a redraw
          setTimeout(function () {
            m.redraw();
          }, 0);

          window.addEventListener('resize', function () {
            pagination = nodes['pagination'];
            pagination.forEach(function (node) {
              if (node.rendered) {
                options.calculateMaxNumbersToShow(node.$element.offsetWidth);
              }
            }.bind(options));
          }.bind(options));
        }.bind(options));

        return options;
      },

      view: function (ctrl) {
        return m('.pagination', { config: render.depend('pagination') },
          [
            m('.pagination__arrow', {
              onclick: ctrl.previousPage
            }, m('svg', { fill: 'none', viewBox: '0 0 14 14' },
              m('path', { d: 'M1 4L7 10L13 4', 'stroke-width': 2, })
            )),

            ctrl.list().map(function (ignoreListItem, index, list) {

              if ((ctrl.activePage() > index + ctrl.numbersOnLeftSide()) && ((list.length - ctrl.maxNumbersToShow()) > index)) {
                return;
              }

              if (index + 1 > ctrl.maxNumbersToShow() && index > ctrl.activePage() + ctrl.numbersOnRightSide()) {
                return;
              }

              return m('span.pagination__number', {
                onclick: ctrl.changePage,
                class: ctrl.activePage() === index ? 'active' : ''
              }, index + 1);
            }),

            m('.pagination__arrow', {
              onclick: ctrl.nextPage
            }, m('svg', { fill: 'none', viewBox: '0 0 14 14' },
              m('path', { d: 'M1 4L7 10L13 4', 'stroke-width': 2, })
            )),
          ]
        );
      }
    };

  });
