defineDs('DanskeSpil/Domain/ContentHub/Scripts/EloquaForm', [
  'DanskeSpil/Domain/ContentHub/Scripts/Helpers/ContentHubApi',
], function (Api) {
  const form = document.querySelector('.eloqua-form');
  if (!form) return;

  const cta = form.querySelector('.eloqua-form__cta');
  if (!cta) return;

  const inputFields = form.querySelectorAll('.eloqua-form__form-group input');
  const selectFields = form.querySelectorAll('.eloqua-form__form-group select');

  const validateEmail = (email) => {
    // Validation from https://emailregex.com/
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const getFieldValues = () => {
    const id = form.getAttribute('data-form-id');
    const fieldValues = [...inputFields, ...selectFields]
      .filter(({ id, value }) => id && value)
      .map(({ type, id, checked, value }) => {

        if (type === 'checkbox') {
          return {
            id,
            value: checked ? 1 : 0
          };
        }

        return {
          id,
          value
        };
      });

    return { id, fieldValues };
  };

  const getContext = () => {
    return form.getAttribute('data-form-context') || 'dli';
  };

  const initialize = () => {
    cta.addEventListener('click', async (ev) => {

      ev.preventDefault();
      let canSubmit = true;

      inputFields.forEach((input) => {
        let inputIsValid = input.checkValidity();

        if (input.type === 'email' && inputIsValid) {
          // Make sure our email input field contains a domain.
          inputIsValid = validateEmail(input.value);
        }

        if (!inputIsValid) {
          canSubmit = false;
        }

        input.parentElement.classList.toggle('eloqua-form__field--error', !inputIsValid);
      });

      selectFields.forEach((select) => {
        let selectIsValid = select.checkValidity();

        if (!selectIsValid) {
          canSubmit = false;
        }

        select.parentElement.classList.toggle('eloqua-form__field--error', !selectIsValid);
      });

      if (!canSubmit) return;

      await Api.sendCampaignForm(getFieldValues(), getContext());
      window.scrollTo(0, 0);
      form.classList.add('eloqua-form--submitted');
    });
  };

  initialize();

});
