defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Mediahub/Episode', function () {

  const isEllipsisActive = (element) => element.offsetHeight < element.scrollHeight;

  const addReadMoreToggleHandlers = (textElements) => {
    textElements.forEach((textElement) => {
      textElement.querySelector('.mediahub-series-content__episode-read-more')?.addEventListener('click', () => {
        textElement.classList.toggle('mediahub-series-content__episode--open-read-more');
      });
    });
  };

  const showReadMoreToggles = (textElements) => {
    textElements.forEach((textElement) => {
      textElement.classList.remove('mediahub-series-content__episode--show-read-more');
      if (isEllipsisActive(textElement.querySelector('.mediahub-series-content__episode-text'))) {
        textElement.classList.add('mediahub-series-content__episode--show-read-more');
      }
    });
  };

  const initialize = () => {
    const episodes = [...document.querySelectorAll('.mediahub-series-content__episode')];
    const textElements = episodes.filter((episode) => episode.querySelector('.mediahub-series-content__episode-text'));
    if (!textElements.length) return;

    addReadMoreToggleHandlers(textElements);
    showReadMoreToggles(textElements);

    window.addEventListener('resize', () => showReadMoreToggles(episodes));

    const podcastEpisodes = document.querySelectorAll('.mediahub-series-content__episode--podcast[data-podcastid]');
    podcastEpisodes.forEach((element) => {
      const podcastID = element.attributes['data-podcastid'].value;
      if (!podcastID) return;

      document.getElementById('podcast-item' + podcastID).addEventListener('click', function () {
        window.open('/dli/scapi/podcast/episode/' + podcastID, 'Podcast', 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=525');
      });
    });
  };

  initialize();
});