defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Mediahub/Navbar', function () {

  const makeHistoryAvailable = (navbar) => {
    const selectedLink = navbar.querySelector('.mediahub-navbar__link--selected');

    if (selectedLink) {
      // If there's a selected link, we reset the storage item.
      localStorage.setItem('MediahubNavbarItem', JSON.stringify([{ name: selectedLink.innerText, url: location.pathname }]));
    }

    if (!selectedLink) {
      const backLink = document.querySelector('.js-mediahub-navbar-backlink');
      if (!backLink) return;

      // If we're on a page that doesn't have a selected link, we need to see if we have the previous link in localstorage.
      let localStorageItems = localStorage.getItem('MediahubNavbarItem');

      if (localStorageItems) {
        // We have one or more previous links from localstorage,
        // so we need to update the backlink.
        localStorageItems = JSON.parse(localStorageItems);
        const latestItem = localStorageItems[localStorageItems.length - 1];

        if (latestItem.url !== location.pathname) {
          // We've checked that the user didn't reload the page, the URL is not going to be added twice in a row.

          const name = document.querySelector('[data-navbar-name]')?.getAttribute('data-navbar-name') || '';

          localStorageItems.push({ name, url: location.pathname });
        }

        // Get the details from the previous item, and use them for the backlink.
        const previousItem = localStorageItems[localStorageItems.length - 2];
        backLink.href = previousItem.url;
        backLink.innerText = `tilbage${previousItem.name ? ' til "' + previousItem.name + '"' : ''}`;

        // Update the localstorage.
        localStorage.setItem('MediahubNavbarItem', JSON.stringify(localStorageItems));
      }

      // We now have all the details for the backlink, so we can remove the hidden class.
      backLink.classList.remove('mediahub-navbar-backlink--hidden');

      // Override the default behavior of clicking the backlink, so we get a chance to pop the localstorage array.
      backLink.addEventListener('click', (ev) => {
        ev.preventDefault();
        let localStorageItems = localStorage.getItem('MediahubNavbarItem');

        if (localStorageItems) {
          // Update the localstorage, and go to the previous page.
          localStorageItems = JSON.parse(localStorageItems);
          localStorageItems.pop();
          localStorage.setItem('MediahubNavbarItem', JSON.stringify(localStorageItems));
          location.href = backLink.href;
        } else {
          // There's no localstorage, user might have accessed the page directly from another site.
          // So we use the default href set by the backend in the DOM.
          location.href = backLink.href;
        }
      });
    }
  };

  const addHoverEffectToLinkItems = (navbar) => {
    const links = navbar.querySelectorAll('.mediahub-navbar__link');
    links?.forEach((link) => {
      link.addEventListener('mouseover', () => navbar.classList.add('mediahub-navbar--link-hover'));
      link.addEventListener('mouseout', () => navbar.classList.remove('mediahub-navbar--link-hover'));
    });
  };

  const initialize = () => {
    const navbar = document.querySelector('.mediahub-navbar');
    if (!navbar) return;

    makeHistoryAvailable(navbar);
    addHoverEffectToLinkItems(navbar);
  };

  initialize();

});
