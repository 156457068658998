defineDs('DanskeSpil/Domain/Poker/Script/PokerAppConsent',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Poker/Script/ToolTip'
  ], function (ApiRequest, Component, Tooltip) {

    Component('poker-consent', function (m, route, settings) {
      var root = {
        controller: function () {
          this.loadFailedText = settings.loadFailedText;
          this.toggleBoxText = settings.toggleBoxText;
          this.toolTip = settings.toolTip;
          this.tryAgainText = settings.tryAgainText;
          this.loading = true;
          this.permissionsAccepted = false;
          this.hasError = false;
          this.showToolTip = m.prop(false);
          var lastAction = null;

          var toolTipTrigger = document.querySelector('.poker-consent__tooltip');
          var overlay = document.querySelector('.poker-consent__overlay');
          var spinner = document.querySelector('.poker-consent__spinner-wrapper');

          toolTipTrigger.addEventListener('click', function () {
            this.showToolTip(true);
            this.toggleOverlay(true);
          }.bind(this));

          overlay.addEventListener('click', function () {
            if (this.showToolTip()) {
              this.showToolTip(false);
              this.toggleOverlay(false);
            }
          }.bind(this));

          this.toggleOverlay = function (on) {
            overlay.classList[on ? 'add' : 'remove']('poker-consent__overlay--show');
          };

          this.toggleSpinner = function (on) {
            spinner.classList[on ? 'add' : 'remove']('poker-consent__spinner-wrapper--show');
          };

          this.handlePermissionToggle = function () {
            lastAction = this.handlePermissionToggle;
            this.toggleOverlay(true);
            this.toggleSpinner(true);
            setPermissions()
              .then(function () {
                this.permissionsAccepted = !this.permissionsAccepted;
                this.toggleOverlay(false);
                this.toggleSpinner(false);
                m.redraw();
              }.bind(this))
              .catch(onError);
          }.bind(this);

          this.tryAgain = function () {
            this.hasError = false;
            this.toggleSpinner(true);
            m.redraw();
            lastAction();
          }.bind(this);

          var onError = function () {
            this.hasError = true;
            this.toggleSpinner(false);
            m.redraw();
          }.bind(this);

          var getPermissions = function () {
            return ApiRequest({
              url: '/dli/scapi/json/consent/apppermissions/get'
            });
          };

          var setPermissions = function () {
            return ApiRequest({
              url: '/dli/scapi/json/consent/apppermissions/post',
              method: 'POST',
              customErrors: 404,
              noCache: true,
              avoidSimultaneous: false,
              data: !this.permissionsAccepted
            });
          }.bind(this);

          var init = function () {
            lastAction = init;
            getPermissions()
              .then(function (result) {
                if (result.operationSuccess) {
                  this.permissionsAccepted = result.appPermissionsAccepted;
                  this.toggleOverlay(false);
                  m.redraw();
                } else {
                  console.error('Failed to fetch permissions:', result);
                  return onError();
                }
                this.toggleSpinner(false);
              }.bind(this))
              .catch(onError);
          }.bind(this);
          init();
        },

        view: function (controller) {
          return [
            controller.hasError ? m('.poker-consent__elouqa-error-wrapper', [
              m('svg.poker-consent__error-arrow', [
                m('use', { href: '/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/Icons.svg#roundArrow_use' })
              ]),
              m('.poker-consent__error-text', controller.loadFailedText),
              m('.poker-consent__error-button', { onclick: controller.tryAgain }, controller.tryAgainText)
            ]) : null,
            m('.poker-consent__toggle-box', [
              m('.poker-consent__toggle-box-text', controller.toggleBoxText),
              m('.poker-consent__toggle', {
                class: controller.permissionsAccepted ? 'poker-consent__toggle--accepted' : '',
                onclick: controller.handlePermissionToggle
              }, [
                m('.poker-consent__toggle-background'),
                m('.poker-consent__toggle-knob')
              ])
            ]),
            m(Tooltip, {
              text: controller.toolTip,
              trigger: 'span.poker-consent__tooltip-trigger',
              show: controller.showToolTip
            })
          ];
        }
      };

      route('/', root);
    });
  });

