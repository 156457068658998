defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Mediahub/Tracking', [
  'Shared/Framework/Ensighten/Scripts/Ensighten',
], function (Ensighten) {

  const pushGaEventOnPageRedirect = ({ action = 'cta_click', category = 'pokerkaelderen', label = '', href }) => {
    Ensighten.pushGaEventOnPageRedirect(category, action, label);
    if (href) {
      setTimeout(() => {
        location.href = href;
      }, 100);
    }
  };

  const pushGaEvent = ({ action = 'cta_click', category = 'pokerkaelderen', label = '' }) => {
    Ensighten.pushGaEvent(category, action, label);
  };

  const addTrackingToLinks = ({ links, action = 'cta_click', label, noRedirect = false }) => {
    links.forEach((link) => {
      link.addEventListener('click', (ev) => {
        if (noRedirect) {
          pushGaEvent({ action, label });
        } else {
          ev.preventDefault();
          const href = ev.currentTarget.hasAttribute('href') ? ev.currentTarget.getAttribute('href') : ev.currentTarget.querySelector('a')?.href;
          pushGaEventOnPageRedirect({
            action,
            href,
            label: label || ev.currentTarget.innerText,
          });
        }
      });
    });
  };

  const initialize = () => {
    const navbarLinks = document.querySelectorAll('.mediahub-navbar .mediahub-navbar__link');
    if (navbarLinks.length) addTrackingToLinks({ links: navbarLinks, action: 'menu_click' });

    const navbarBackLinks = document.querySelectorAll('.mediahub-navbar .mediahub-navbar-backlink');
    if (navbarBackLinks.length) addTrackingToLinks({ links: navbarBackLinks, action: 'menu_click' });

    const seriesHighlights = document.querySelectorAll('.mediahub-series-highlight');
    if (seriesHighlights.length) {
      seriesHighlights.forEach((seriesHighlight) => {
        const seriesTitle = seriesHighlight.querySelector('.mediahub-series-highlight__title').innerText.replace(/\nVis flere/gi, '');
        const desktopLink = seriesHighlight.querySelector('.mediahub-series-highlight__title--show-more');
        const mobileLink = seriesHighlight.querySelector('.mediahub-series-highlight__episode-show-more');
        if ((desktopLink || mobileLink) && seriesTitle) addTrackingToLinks({ links: [desktopLink, mobileLink], label: `${seriesTitle} - Vis flere` });
        const episodes = seriesHighlight.querySelectorAll('.mediahub-series-highlight__episode');
        if (episodes.length && seriesTitle) {
          episodes.forEach((episode) => {
            const episodeTitle = episode.querySelector('.mediahub-series-highlight__episode-title')?.innerText;
            if (episodeTitle) addTrackingToLinks({ links: [episode], action: `click_${seriesTitle}`, label: episodeTitle });
          });
        }
      });
    }

    const episodesHighlight = document.querySelectorAll('.mediahub-episodes-highlight');
    if (episodesHighlight.length) {
      episodesHighlight.forEach((episodeHighlight) => {
        const episodesTitle = episodeHighlight.querySelector('.mediahub-episodes-highlight__title')?.innerText;
        const episodes = episodeHighlight.querySelectorAll('.mediahub-episodes-highlight__episode');
        if (episodes && episodesTitle) {
          episodes.forEach((episode) => {
            const episodeTitle = episode.querySelector('.mediahub-episodes-highlight__episode-title')?.innerText;
            if (episodeTitle) addTrackingToLinks({ links: [episode], action: `click_${episodesTitle}`, label: episodeTitle });
          });
        }
      });
    }

    const episodesLatest = document.querySelectorAll('.mediahub-episodes-latest');
    if (episodesLatest.length) {
      episodesLatest.forEach((episodeLatest) => {
        const episodes = episodeLatest.querySelectorAll('.mediahub-episodes-latest__episode');
        episodes?.forEach((episode) => {
          const episodeTitle = episode.querySelector('.mediahub-episodes-latest__episode-title')?.innerText;
          if (episodeTitle) {
            const podcastMobileCta = episode.querySelector('.mediahub-episodes-latest__episode-cta--mobile');
            const noRedirect = podcastMobileCta && window.getComputedStyle(podcastMobileCta, null).display === 'none';
            addTrackingToLinks({ links: [episode], action: 'click_hero', label: episodeTitle, noRedirect });
          }
        });
      });
    }

    const episodesGrid = document.querySelectorAll('.mediahub-episodes-grid');
    if (episodesGrid.length) {
      episodesGrid.forEach((episodeGrid) => {
        const episodes = episodeGrid.querySelectorAll('.mediahub-episodes-grid__episode');
        episodes?.forEach((episode) => {
          const episodeTitle = episode.querySelector('.mediahub-episodes-grid__episode-title')?.innerText;
          if (episodeTitle) {
            addTrackingToLinks({ links: [episode], action: 'click_episode', label: episodeTitle });
          }
        });
      });
    }

    const seriesContentEpisodes = document.querySelectorAll('.mediahub-series-content__episode');
    if (seriesContentEpisodes.length) {
      seriesContentEpisodes.forEach((episode) => {
        const episodeTitle = episode.querySelector('.mediahub-series-content__episode-title')?.innerText;
        if (episodeTitle) {
          const cta = episode.querySelector('.mediahub-series-content__episode-cta');
          addTrackingToLinks({ links: [cta], action: 'click_episode', label: episodeTitle });
        }
      });
    }

    const episodeContent = document.querySelector('.mediahub-episode-content');
    const episodeTitle = episodeContent?.querySelector('.mediahub-episode-content__episode-title')?.innerText;
    if (episodeTitle) {
      const podcastMobileCta = episodeContent.querySelector('.mediahub-episode-content__episode-cta--mobile');
      if (podcastMobileCta && window.getComputedStyle(podcastMobileCta, null).display !== 'none') {
        addTrackingToLinks({ links: [podcastMobileCta], action: 'play_episode', label: episodeTitle });
      } else {
        const cta = episodeContent.querySelector('.mediahub-episode-content__episode-cta');
        if (cta) addTrackingToLinks({ links: [cta], action: 'play_episode', label: episodeTitle, noRedirect: true });
      }
    }

    const downloadAppCta = document.querySelector('.mediahub-download-app__cta--download');
    if (downloadAppCta) addTrackingToLinks({ links: [downloadAppCta], action: 'download_poker_client' });
  };

  initialize();

});
